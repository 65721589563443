/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import getCDNImage from '../../utils/getCDNImage';

import { ReactComponent as WishListIcon } from '../../images/icons/wishlist_transparent.svg';
import { ReactComponent as WishListSelectedIcon } from '../../images/icons/wishlist.svg';
import { ReactComponent as PugSadIcon } from '../../images/icons/pug_failed.svg';
import { ReactComponent as AdultContent } from '../../images/icons/erotics.svg';

import styles from './ProductCard.module.scss';
import { Link } from 'react-router-dom';
import Price from '../Price/Price';
import useWishlist from '../../utils/useWishlist';
import { FormattedMessage } from 'react-intl';
import ToggleAdultContent from '../ToggleAdultContent';
import useAdultContentHandlers from '../../utils/useAdultContentHandlers';

const ProductCard = ({ classList = {}, product = {}, isWishlist }) => {
  const { toggleWishlistItem, isWishlistItem } = useWishlist();
  const { adultContentAllowed } = useAdultContentHandlers();
  const { id } = product;
  const isLikedProduct = isWishlistItem(id);

  const handleWishlistBtnPress = (e) => {
    e.preventDefault();
    toggleWishlistItem(id);
  };

  if (product?.adult && !adultContentAllowed) {
    return (
      <div className={classNames(styles.productCard, classList.root)} data-testid="ProductCard">
        <Link className={classList.productLink} to={product.pdp_path}>
          <div className={classList.productPic || styles.imageContainer}>
            <AdultContent width={80} height={80} viewBox="0 0 16 16" className={styles.adultContentPic} />
          </div>
          {!!product?.adult && <ToggleAdultContent classList={{ root: styles.toggleAdultContent }} />}
          <div className={classList.content || styles.content}>
            <p className={classList.name || styles.name} data-testid="ProductCardName">
              {product.name}
            </p>
            {product.price && (
              <Price
                classList={{ root: classList.price || styles.price }}
                price={product.price}
                currency={process.env.REACT_APP_WEBSITE_CURRENCY}
                isCurrencyShown={false}
              />
            )}
          </div>
        </Link>
      </div>
    );
  }

  if (!product || product?.unavailable) {
    return (
      <div className={classNames(styles.productCard, classList.root)} data-testid="ProductCard">
        <div className={classList.productLink}>
          <div className={classList.productPic || styles.imageContainer}>
            <PugSadIcon className={styles.unavailableProductImg} />
          </div>
          <div className={classList.content || styles.content}>
            <p className={classList.name || styles.name} data-testid="ProductCardName">
              <FormattedMessage id="cart.itemUnavailable" defaultMessage="Product not available anymore" />
            </p>
            {!isWishlist && (
              <span
                className={classNames(styles.heart, {
                  [styles.liked]: isLikedProduct,
                })}
                data-pid={id}
                onClick={(e) => handleWishlistBtnPress(e)}
              >
                {isLikedProduct ? <WishListSelectedIcon /> : <WishListIcon />}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={classNames(styles.productCard, classList.root)} data-testid="ProductCard">
        <Link className={classList.productLink} to={product.pdp_path}>
          <div className={classList.productPic || styles.imageContainer}>
            <img
              src={`https://res.cloudinary.com/crop/image/fetch/f_auto,q_70,fl_lossy,c_scale/${getCDNImage(
                product.thumbs[0]
              )}`}
              alt={product.name}
              title={product.name}
            />
          </div>
          {!!product?.adult && <ToggleAdultContent classList={{ root: styles.toggleAdultContent }} />}
          <div className={classList.content || styles.content}>
            <p className={classList.name || styles.name} data-testid="ProductCardName">
              {product.name}
            </p>
            {product.price && (
              <Price
                classList={{ root: classList.price || styles.price }}
                price={product.price}
                currency={process.env.REACT_APP_WEBSITE_CURRENCY}
                isCurrencyShown={false}
              />
            )}
            {!isWishlist && (
              <span
                className={classNames(styles.heart, {
                  [styles.liked]: isLikedProduct,
                })}
                data-pid={id}
                onClick={(e) => handleWishlistBtnPress(e)}
              >
                {isLikedProduct ? <WishListSelectedIcon /> : <WishListIcon />}
              </span>
            )}
          </div>
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = ({ profile, clickout }) => ({
  profile,
  clickout,
});

ProductCard.displayName = 'ProductCard';
export default connect(mapStateToProps)(ProductCard);
